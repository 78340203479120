// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-archives-jsx": () => import("./../../../src/pages/archives.jsx" /* webpackChunkName: "component---src-pages-archives-jsx" */),
  "component---src-pages-articles-jsx": () => import("./../../../src/pages/articles.jsx" /* webpackChunkName: "component---src-pages-articles-jsx" */),
  "component---src-pages-casestudies-marcote-jsx": () => import("./../../../src/pages/casestudies/marcote.jsx" /* webpackChunkName: "component---src-pages-casestudies-marcote-jsx" */),
  "component---src-pages-casestudies-mediservices-jsx": () => import("./../../../src/pages/casestudies/mediservices.jsx" /* webpackChunkName: "component---src-pages-casestudies-mediservices-jsx" */),
  "component---src-pages-casestudies-micherowholefoods-jsx": () => import("./../../../src/pages/casestudies/micherowholefoods.jsx" /* webpackChunkName: "component---src-pages-casestudies-micherowholefoods-jsx" */),
  "component---src-pages-casestudies-revivemydevice-jsx": () => import("./../../../src/pages/casestudies/revivemydevice.jsx" /* webpackChunkName: "component---src-pages-casestudies-revivemydevice-jsx" */),
  "component---src-pages-categories-jsx": () => import("./../../../src/pages/categories.jsx" /* webpackChunkName: "component---src-pages-categories-jsx" */),
  "component---src-pages-digital-services-jsx": () => import("./../../../src/pages/digital-services.jsx" /* webpackChunkName: "component---src-pages-digital-services-jsx" */),
  "component---src-pages-home-jsx": () => import("./../../../src/pages/home.jsx" /* webpackChunkName: "component---src-pages-home-jsx" */),
  "component---src-pages-information-technology-jsx": () => import("./../../../src/pages/information-technology.jsx" /* webpackChunkName: "component---src-pages-information-technology-jsx" */),
  "component---src-pages-success-jsx": () => import("./../../../src/pages/success.jsx" /* webpackChunkName: "component---src-pages-success-jsx" */),
  "component---src-pages-theme-landing-jsx": () => import("./../../../src/pages/theme-landing.jsx" /* webpackChunkName: "component---src-pages-theme-landing-jsx" */),
  "component---src-pages-web-development-jsx": () => import("./../../../src/pages/web-development.jsx" /* webpackChunkName: "component---src-pages-web-development-jsx" */),
  "component---src-templates-category-jsx": () => import("./../../../src/templates/category.jsx" /* webpackChunkName: "component---src-templates-category-jsx" */),
  "component---src-templates-contact-jsx": () => import("./../../../src/templates/contact.jsx" /* webpackChunkName: "component---src-templates-contact-jsx" */),
  "component---src-templates-post-jsx": () => import("./../../../src/templates/post.jsx" /* webpackChunkName: "component---src-templates-post-jsx" */),
  "component---src-templates-post-list-alt-jsx": () => import("./../../../src/templates/postListAlt.jsx" /* webpackChunkName: "component---src-templates-post-list-alt-jsx" */),
  "component---src-templates-post-list-full-jsx": () => import("./../../../src/templates/postListFull.jsx" /* webpackChunkName: "component---src-templates-post-list-full-jsx" */),
  "component---src-templates-post-list-jsx": () => import("./../../../src/templates/postList.jsx" /* webpackChunkName: "component---src-templates-post-list-jsx" */),
  "component---src-templates-tag-jsx": () => import("./../../../src/templates/tag.jsx" /* webpackChunkName: "component---src-templates-tag-jsx" */)
}

